#top-bar {
  display: flex;
  align-items: center;
  height: 5em;
}
#top-bar-logo {
  height: 5em;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

#contact-button {
  position: absolute;
  right: 1em;
}
