body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.placeholderImage {
  width: 100%;
  padding-top: 1em;
  padding-right: 5vw;
  padding-left: 5vw;
  box-sizing: border-box;
}

#top-bar {
  display: flex;
  align-items: center;
  height: 5em;
}
#top-bar-logo {
  height: 5em;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

#contact-button {
  position: absolute;
  right: 1em;
}

footer {
    height: 8em;
    color:white
}
.my-masonry-grid {
  display: flex;
  margin-left: -30px;
}
.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin-bottom: 60px;
}

.galleryPhotos {
  width: 20em;
}

